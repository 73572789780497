<template>
  <div class="login">
    <div class="login-body">
      <div class="login-box">
        <div class="login-logo">
          <img src="@/assets/login/u16.png" alt="" />
          <img class="logo-dian" src="@/assets/login/u8589.svg" alt="" />
          <span class="logo-title" v-if="loginTypeCard == 'login'">{{
            $t('login.enterLogin')
          }}</span>
          <span class="logo-title" v-if="loginTypeCard == 'register'">{{
            $t('login.enterRegist')
          }}</span>
        </div>
        <!--登陆注册页面-->
        <div class="login-card" v-if="loginTypeCard == 'login'">
          <el-tabs v-model="loginType" type="card">
            <el-tab-pane :label="$t('login.passwordLogin')" name="password">
              <el-form
                ref="loginPassword"
                :model="loginPassword"
                :rules="passwordRules"
                class="login-form"
                autocomplete="on"
                label-position="left"
              >
                <div class="password-card">
                  <el-form-item prop="enterpriseName">
                    <el-input
                      :placeholder="$t('login.enterName')"
                      prefix-icon="el-icon-office-building"
                      v-model="loginPassword.enterpriseName"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="username">
                    <el-input
                      :placeholder="$t('login.loginAccount')"
                      prefix-icon="el-icon-s-custom"
                      v-model="loginPassword.username"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="password" style="margin-bottom: 0">
                    <el-input
                      type="password"
                      :placeholder="$t('login.password')"
                      prefix-icon="el-icon-unlock"
                      v-model="loginPassword.password"
                    >
                    </el-input>
                  </el-form-item>
                  <br />
                  <!-- <div class="remember">
                    <el-checkbox v-model="remember" style="float: left">{{
                      $t('login.remenberAccount')
                    }}</el-checkbox>
                    <el-link target="_blank" style="float: right">{{
                      $t('login.forgetPsw')
                    }}</el-link>
                    <p style="clear: both"></p>
                  </div> -->
                </div>
              </el-form>
            </el-tab-pane>
            <el-tab-pane :label="$t('login.codeLogin')" name="code">
              <el-form
                ref="loginCode"
                :model="loginCode"
                :rules="codeRules"
                class="login-form"
                autocomplete="on"
                label-position="left"
              >
                <div class="password-card">
                  <el-form-item prop="enterpriseName">
                    <el-input
                      :placeholder="$t('login.enterName')"
                      prefix-icon="el-icon-office-building"
                      v-model="loginCode.enterpriseName"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="phone">
                    <el-input
                      :placeholder="$t('login.mobilePhone')"
                      prefix-icon="el-icon-mobile-phone"
                      v-model="loginCode.phone"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="code">
                    <el-input
                      :placeholder="$t('login.verifyCode')"
                      v-model="loginCode.code"
                      prefix-icon="el-icon-unlock"
                      class="input-with-select"
                    >
                      <el-button
                        slot="append"
                        @click="openCode"
                        :disabled="codeDisabled"
                        >{{ codeMsg }}</el-button
                      >
                    </el-input>
                  </el-form-item>
                </div>
              </el-form>
            </el-tab-pane>
          </el-tabs>
          <!-- 登陆 -->
          <div class="login-btn">
            <div class="btn-card">
              <el-button class="login-color" @click="loginTo">{{
                $t('login.signIn')
              }}</el-button>
            </div>
            <div class="btn-card">
              <el-button
                class="sign-color"
                @click="loginTypeCard = 'register'"
                >{{ $t('login.signUp') }}</el-button
              >
            </div>
            <!--<div class="btn-card">-->
            <!--<el-button type="primary" @click="downloadSSL">-->
            <!--下载证书-->
            <!--</el-button>-->
            <!--</div>-->
          </div>
        </div>
        <!--注册页面-->
        <div class="login-card" v-if="loginTypeCard == 'register'">
          <div class="register-title">{{ $t('login.enterRegist') }}</div>
          <el-form
            ref="registerCard"
            :model="register"
            :rules="registerRules"
            class="login-form"
            autocomplete="on"
            label-position="left"
          >
            <div class="password-card">
              <el-form-item prop="enterpriseName">
                <el-input
                  :placeholder="$t('login.enterName')"
                  prefix-icon="el-icon-office-building"
                  v-model="register.enterpriseName"
                >
                </el-input>
              </el-form-item>
              <el-form-item prop="username">
                <el-input
                  :placeholder="$t('login.loginAccount')"
                  prefix-icon="el-icon-s-custom"
                  v-model="register.username"
                >
                </el-input>
              </el-form-item>
              <el-form-item prop="email">
                <el-input
                  :placeholder="$t('login.email')"
                  prefix-icon="el-icon-message"
                  v-model="register.email"
                >
                </el-input>
              </el-form-item>
              <el-form-item prop="phone">
                <el-input
                  class="phone-class"
                  :placeholder="$t('login.mobilePhone')"
                  prefix-icon="el-icon-mobile-phone"
                  v-model="register.phone"
                >
                  <el-select
                    v-model="prefix"
                    slot="prepend"
                    :placeholder="$t('common.pleaseSelect')"
                  >
                    <el-option label="+1" value="1"></el-option>
                    <el-option label="+86" value="86"></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item prop="smsCode">
                <el-input
                  :placeholder="
                    $t('common.pleaseEnter') + $t('login.verifyCode')
                  "
                  v-model="register.smsCode"
                  prefix-icon="el-icon-key"
                  class="input-with-select"
                >
                  <el-button
                    slot="append"
                    @click="openRegisterCode"
                    :disabled="codeDisabled"
                    >{{ codeMsg }}</el-button
                  >
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  type="password"
                  :placeholder="$t('login.password')"
                  prefix-icon="el-icon-unlock"
                  v-model="register.password"
                >
                </el-input>
              </el-form-item>
              <el-form-item prop="confirmPassword">
                <el-input
                  type="password"
                  :placeholder="$t('login.confirmPsw')"
                  prefix-icon="el-icon-unlock"
                  v-model="register.confirmPassword"
                >
                </el-input>
              </el-form-item>
            </div>
          </el-form>
          <div class="login-btn">
            <div class="btn-card">
              <el-button class="login-color" @click="registerTo">{{
                $t('login.signUp')
              }}</el-button>
            </div>
            <div class="btn-card">
              <el-button class="sign-color" @click="loginTypeCard = 'login'">{{
                $t('login.backToLogin')
              }}</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="copyright"><a target="_blank" href="https://beian.miit.gov.cn">皖ICP备2022001458号-2</a></div>
      </div>
    </div>
  </div>
</template>
<script>
import { post, del, get, putUrl } from '@/api/http'
import axios from 'axios'
import baseURL from '@/api/baseURL.js'
import qs from 'qs'
import { getToken, setToken, removeToken } from '@/utils/auth'

export default {
  name: 'Login',
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!value) {
        callback(
          new Error(
            this.$t('common.pleaseEnter') + this.$t('login.loginAccount')
          )
        )
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(
          new Error(this.$t('common.pleaseEnter') + this.$t('login.password'))
        )
      } else {
        if (value.length < 6) {
          callback(new Error(this.$t('login.pswless6')))
        }
        callback()
      }
    }
    //验证两次输入密码
    const validateConfirmPassword = (rule, value, callback) => {
      if (!value) {
        callback(
          new Error(this.$t('common.pleaseEnter') + this.$t('login.password'))
        )
      } else {
        if (value != this.register.password) {
          callback(new Error(this.$t('login.pswNotMatch')))
        }
        callback()
      }
    } //验证登录账号是否含下划线_
    const validateConfirmUsername = (rule, value, callback) => {
      if (!value) {
        callback(
          new Error(
            this.$t('common.pleaseEnter') + this.$t('login.loginAccount')
          )
        )
      } else {
        let regex = /\_+/g
        let isRegex = regex.test(value)
        if (isRegex) {
          callback(new Error(this.$t('login.notInput_')))
        }
        callback()
      }
    }
    return {
      loginPassword: {
        enterpriseName: '',
        username: '',
        password: '',
      },
      loginCode: {},
      passwordRules: {
        enterpriseName: [
          {
            required: true,
            message: this.$t('common.pleaseEnter') + this.$t('login.enterName'),
            trigger: 'blur',
          },
        ],
        username: [
          { required: true, trigger: 'blur', validator: validateUsername },
        ],
        password: [
          { required: true, trigger: 'blur', validator: validatePassword },
        ],
      },
      codeRules: {
        // code: [{
        //     required: true,
        //     message: '请输入验证码',
        //     trigger: 'blur'
        // }],
        enterpriseName: [
          {
            required: true,
            message: this.$t('common.pleaseEnter') + this.$t('login.enterName'),
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            message:
              this.$t('common.pleaseEnter') + this.$t('login.mobilePhone'),
            trigger: 'blur',
          },
        ],
        smsCode: [
          {
            required: true,
            message:
              this.$t('common.pleaseEnter') + this.$t('login.verifyCode'),
            trigger: 'blur',
          },
        ],
      },
      loginType: 'password',
      redirect: undefined,
      otherQuery: {},
      remember: false,
      loading: false,
      //注册
      loginTypeCard: 'login',
      registerRules: {
        password: [
          { required: true, trigger: 'blur', validator: validatePassword },
        ],
        confirmPassword: [
          {
            required: true,
            trigger: 'blur',
            validator: validateConfirmPassword,
          },
        ],
        enterpriseName: [
          {
            required: true,
            message: this.$t('common.pleaseEnter') + this.$t('login.enterName'),
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            message:
              this.$t('common.pleaseEnter') + this.$t('login.mobilePhone'),
            trigger: 'blur',
          },
        ],
        username: [
          {
            required: true,
            trigger: 'blur',
            validator: validateConfirmUsername,
          },
        ],
        email: [
          {
            required: true,
            message: this.$t('common.pleaseEnter') + this.$t('login.email'),
            trigger: 'blur',
          },
        ],
      },
      register: {},
      prefix: '86',
      codeMsg: this.$t('login.getCode'),
      codeDisabled: false,
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true,
    },
  },
  methods: {
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    loginTo() {
      if (this.loginType == 'password') {
        this.$refs.loginPassword.validate((valid) => {
          if (valid) {
            let obj = {
              ...this.loginPassword,
            }
            this.$store
              .dispatch('user/login', obj)
              .then((res) => {
                if (res) {
                  if (res.code == 400) {
                    this.$message.error(this.$t('login.errorNamePsw'))
                  }
                  if (res.code == 401) {
                    this.$message.error(this.$t('login.userEnabled'))
                  }
                  // this.$message.error(res);
                } else {
                  this.$router.push({
                    path: this.redirect || '/',
                    query: this.otherQuery,
                  })
                }
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            return false
          }
        })
      } else {
        this.$refs.loginCode.validate((valid) => {
          if (valid) {
            if (this.loginCode.code) {
              let obj = {
                smsCode: this.loginCode.code,
                phone: this.loginCode.phone,
              }
              this.$store
              .dispatch('user/smslogin', obj)
              .then((res) => {
                if (res) {
                  if (res.code == 400) {
                    this.$message.error(this.$t('login.errorNamePsw'))
                  }
                  if (res.code == 401) {
                    this.$message.error(this.$t('login.userEnabled'))
                  }
                  if (res.code == 501) {
                    this.$message.error(this.$t('login.codeExpired'))
                  }
                  // this.$message.error(res);
                } else {
                  this.$router.push({
                    path: this.redirect || '/',
                    query: this.otherQuery,
                  })
                }
              })
              .catch((err) => {
                console.log(err)
              })
              // const data = qs.stringify(obj)
              // axios
              //   .post(baseURL + 'login/sms-login', data)
              //   .then(function (response) {
              //     console.log(response);
              //     if (response.data.code == '1') {
              //       setToken(response.data.data)
              //       this.$router.push({
              //         path: this.redirect || '/',
              //         query: this.otherQuery,
              //       })
              //     }
              //   })
              //   .catch(function (error) {
              //     console.log(error)
              //   })
            }
          } else {
            return false
          }
        })
      }
    },
    openCode() {
      this.$refs.loginCode.validate((valid) => {
        if (valid) {
          post(
            '/sms-login-code/send',
            {
              enterpriseName: this.loginCode.enterpriseName,
              phone: this.loginCode.phone,
            }
          ).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.data)
              this.codeMsg = 61
              this.codeDisabled = true
              this.countTime()
            } else {
              this.$message.error(res.data)
            }
          })
        } else {
          return false
        }
      })
    },
    openRegisterCode() {
      if (this.register.phone) {
        post(
          '/reg/sms-reg',
          {
            prefix: this.prefix,
            phone: this.register.phone,
          }
        ).then((res) => {
          if (res.code == 1) {
            this.$message.success(res.data)
            this.codeMsg = 61
            this.codeDisabled = true
            this.countTime()
          } else {
            this.$message.error(res.data)
          }
        })
      } else {
        this.$message.error(
          this.$t('common.pleaseEnter') + this.$t('login.mobilePhone')
        )
      }
    },
    // 倒计时
    countTime() {
      this.codeMsg = Number(this.codeMsg) - 1
      // 等于0的时候不调用
      if (Number(this.codeMsg) === 0) {
        this.codeMsg = this.$t('login.getCode')
        this.codeDisabled = false
        return
      } else {
        // 递归每秒调用countTime方法，显示动态时间效果,
        setTimeout(this.countTime, 1000)
      }
    },
    registerTo() {
      this.$refs.registerCard.validate((valid) => {
        if (valid) {
          
          post(
            '/reg/submit',
            {
              email: this.register.email,
              enterpriseName: this.register.enterpriseName,
              password: this.register.password,
              phone: this.register.phone,
              smsCode: this.register.smsCode,
              username: this.register.username,
            }
          ).then((res) => {
            if (res.code == 1) {
              this.$message.success(this.$t('login.signUpSuccess'))
              this.loginTypeCard = 'login'
            } else {
              this.$message.error(res.data)
            }
          })
        } else {
          return false
        }
      })
    },
    downloadSSL() {
      const linkNode = document.createElement('a')
      linkNode.style.display = 'none'
      linkNode.href = 'http://47.99.204.31:9528/ssl/keystore.cer' //生成一个Blob URL
      document.body.appendChild(linkNode)
      linkNode.click() //模拟在按钮上的一次鼠标单击
      URL.revokeObjectURL(linkNode.href) // 释放URL 对象
      document.body.removeChild(linkNode)
    },
  },
  mounted() {},
}
</script>
<style scoped>
.login {
  background-color: #f6f7ff;
  width: 100%;
  min-height: 100vh;
}

.login-body {
  text-align: center;
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 700px
}
.footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
  background: #F6F7FF;
}
.footer .copyright{
    line-height: 40px;
    font-size: 12px;
    text-align: center;
}
.footer .copyright a{
  color:#bbb
}
.login-box {
  position: absolute;
  top: 50px;
  left: 50%;
  margin-left: -250px;
  width: 500px;
  height: 500px;
}

.login-box .login-logo {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-logo img {
  height: 50px;
}

.login-logo .logo-dian {
  width: 6px;
  height: 6px;
}

.login-logo .logo-title {
  color: #333399;
  display: inline-block;
  padding: 0 10px;
  font-weight: bold;
  font-size: 20px;
}
/* 登陆表单样式 */
.login-card {
  margin-top: 50px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
}
.login-card ::v-deep .el-input__inner {
  border-color: rgba(209, 209, 230, 1);
}
::v-deep .el-tabs--card .el-tabs__header .el-tabs__item {
  height: 60px;
  line-height: 60px;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  width: 100%;
  height: 60px;
  border: none;
}

::v-deep .el-tabs__item {
  width: 50%;
  height: 100%;
  line-height: 60px;
  font-size: 18px;
  background-color: #ebebf5;
  color: #999;
  border: 0px solid #fff !important;
}

::v-deep .is-active:hover {
  color: #fff;
}

::v-deep .is-active {
  /*padding: 0;*/
  background-color: #333399;
  color: #fff !important;
}
.login-card .password-card {
  padding: 40px 100px 30px;
}
::v-deep .el-input-group__append .el-button {
  background-color: #333399;
  border-radius: 0 3px 3px 0;
  color: #fff;
}

.remember label,
.remember a {
  /* margin-top: 10px; */
  color: #999999;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #333399;
  border-color: #333399;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #333399;
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 35px;
}
::v-deep .el-input__icon {
  font-size: 18px;
  color: #b3b2ce;
}
.login-btn {
  padding-bottom: 30px;
}
.btn-card {
  padding: 0 100px;
}
.btn-card .el-button {
  width: 100%;
  margin-bottom: 20px;
}
.sign-color {
  border-color: #333399;
  color: #333399;
}
.sign-color.el-button:hover {
  background-color: #ebebf5;
  color: #333399;
}

.login-color {
  background-color: #333399;
  border-color: #333399;
  color: #fff;
}

.login-color.el-button:hover {
  background-color: #333399;
  border-color: #333399;
  color: #fff;
}

.register-title {
  border-bottom: 1px solid #e4e7ed;
  background-color: #333399;
  color: #fff;
  font-size: 18px;
  padding: 20px;
}
.phone-class >>> .el-input-group__prepend {
  width: 50px;
}
</style>
